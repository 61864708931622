import React, { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; // For generating unique IDs
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from './AddNewExpense.module.css';

const AddNewExpense = (props) => {
    const { isOpen, onClose, onSuccess } = props
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [itemAdding, setItemAdding] = useState([
        {
            id: uuidv4(),
            category: '',
            expenseName: '',
            expenseNotes: '',
            amount: ''
        },
    ]);
    const [isAddingNow, setIsAddingNow] = useState(false)
    const [paidViaCash, setPaidViaCash] = useState(0)
    const [paidViaQr, setPaidViaQr] = useState(0)
    const [paidViaOnline, setPaidViaOnline] = useState(0)


    // Function to handle adding a new expense item
    const handleAddNewItem = () => {
        const newItem = {
            id: uuidv4(),
            category: '',
            expenseName: '',
            expenseNotes: '',
            amount: ''
        };
        setItemAdding([...itemAdding, newItem]);
    };

    // Function to handle removing an expense item by ID
    const handleRemoveItem = (id) => {
        setItemAdding(itemAdding.filter(item => item.id !== id));
    };

    // Function to handle updating item fields
    const handleItemChange = (id, field, value) => {
        setItemAdding(itemAdding.map(item =>
            item.id === id ? { ...item, [field]: value } : item
        ));
    };

    const addExpenses = async () => {
        setIsAddingNow(true)
        const addExpensesResponse = await fetch(BASE_URL_API + "addExpenses",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: JSON.parse(authCtx?.loginData)?.partnersData?.vendorIdentifier,
                    storeIdentifier: activeStore?.storeIdentifier,
                    authIdentifier: authCtx?.authIdentifier,
                    expenses: itemAdding,
                    paidViaCash : paidViaCash,
                    paidViaOnline : paidViaOnline,
                    paidViaQr : paidViaQr,
                    totalExpense : getTotalAmount()
                })
            });

        if (!addExpensesResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const addExpensesRespo = await addExpensesResponse.json()
            if (addExpensesRespo.status === "success") {
                onSuccess()
            } else {
                if (addExpensesRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(addExpensesRespo)
        }
        setIsAddingNow(false)
    }



    // Function to calculate total amount
    const getTotalAmount = () => {
        return itemAdding.reduce((total, item) => {
            return total + parseFloat(item.amount || 0); // Convert amount to float, handle NaN if empty
        }, 0);
    };


    console.log(itemAdding)

    if (!isOpen) {
        return null
    } else {
        if (activeStore === null) {
            return (
                <div className={styles.mainWrapper}>
                    <div className={styles.mainContainer}>
                        <div className={styles.mainContent}>
                            <div className={styles.titleWrapper}>Add New Expense</div>
                            <div className={styles.tableWrapper}>
                                Please select a store
                            </div>
                            <div className={styles.footer}>
                                <button className={styles.backBtn} onClick={() => onClose()} >Back</button>
                                <button className={styles.updateBtn} onClick={() => addExpenses()} >Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.titleWrapper}>Add New Expense</div>
                        <div className={styles.tableWrapper}>
                            <div className={styles.tableHeader}>
                                <div className={styles.sn}>#</div>
                                <div className={styles.category}>Category</div>
                                <div className={styles.expense}>Expenses</div>
                                <div className={styles.amount}>Amount</div>
                                <div className={styles.action}>Action</div>
                            </div>
                            <div className={styles.tableDataWrapper}>
                                {itemAdding.map((item, index) => (
                                    <div className={styles.rowWrapper} key={item.id}>
                                        <div className={styles.sn}>{index + 1}.</div>
                                        <div className={styles.category}>
                                            <select
                                                value={item.category}
                                                onChange={(e) => handleItemChange(item.id, 'category', e.target.value)}
                                            >
                                                <option>Select Category</option>
                                                <option>NA</option>
                                                <option>Cosmetics</option>
                                            </select>
                                        </div>
                                        <div className={styles.expense}>
                                            <div className={styles.expenseName}>
                                                <input
                                                    placeholder="Expense name..."
                                                    value={item.expenseName}
                                                    onChange={(e) => handleItemChange(item.id, 'expenseName', e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.expenseNotes}>
                                                <textarea
                                                    placeholder="Expense notes..."
                                                    value={item.expenseNotes}
                                                    onChange={(e) => handleItemChange(item.id, 'expenseNotes', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.amount}>
                                            <input
                                                type="number"
                                                placeholder="Amount"
                                                value={item.amount}
                                                onChange={(e) => handleItemChange(item.id, 'amount', e.target.value)}
                                            />
                                        </div>
                                        <div className={styles.action}>
                                            {index === itemAdding.length - 1 && (
                                                <button className={styles.addBtn} onClick={handleAddNewItem}>Add New Item</button>
                                            )}
                                            <button className={styles.removeBtn} onClick={() => handleRemoveItem(item.id)}>Remove</button>
                                        </div>
                                    </div>
                                ))}

                                <div className={styles.paidWrapper}>
                                    <div className={styles.paidContainer}>
                                        <div className={`${styles.eachOption} ${styles.total}` }>
                                            <div className={styles.eoTitle} >Total : </div>
                                            <div className={styles.eoValue} >₹{getTotalAmount()}</div>
                                        </div>
                                        <div className={styles.eachOption}>
                                            <div className={styles.eoTitle} >Paid Via Cash</div>
                                            <div className={styles.eoValue} ><input value={paidViaCash} onChange={(e) => setPaidViaCash(e.target.value)} /></div>
                                        </div>
                                        <div className={styles.eachOption}>
                                            <div className={styles.eoTitle} >Paid Via QR</div>
                                            <div className={styles.eoValue} ><input value={paidViaQr} onChange={(e) => setPaidViaQr(e.target.value)} /></div>
                                        </div>
                                        <div className={styles.eachOption}>
                                            <div className={styles.eoTitle} >Paid Via Online</div>
                                            <div className={styles.eoValue} ><input value={paidViaOnline} onChange={(e) => setPaidViaOnline(e.target.value)} /></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={styles.footer}>
                            <button className={styles.backBtn} onClick={() => onClose()} >Back</button>
                            {isAddingNow === true ?
                                <button className={styles.updateBtn} >Updating..</button>
                                :
                                <button className={styles.updateBtn} onClick={() => addExpenses()} >Update</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};


export default AddNewExpense;
